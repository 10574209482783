import { useEffect, useState } from "react";
import styled from "styled-components";

import {
  deleteApiKeys,
  getApiKeys,
  postApiKeys,
} from "api/backend/authenticationServiceEndpoints";
import LayoutNew from "components/LayoutNew";
import AddApiKeyModal from "components/AddApiKeyModal";
import { Gap } from "components/Layout";
import { displayName } from "react-quill";
import { TrashIcon } from "components/ui/Icons";

const TableContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  font-weight: 600;
`;

const COLUMNS = [
  {
    dbName: "name",
    displayName: "Name",
  },
  {
    dbName: "key",
    displayName: "Secret Key",
  },
  {
    dbName: "expiredAt",
    displayName: "Valid Until",
  },
];

const Td = styled.td`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;

  text-overflow: ellipsis;
  overflow: auto;

  font-weight: 600;
  z-index: 1;

  padding: 8px;
`;

const ApiTokensPage = () => {
  const [apiKeys, setApiKeys] = useState([]);

  useEffect(() => {
    doPopulateApiKeys();
  }, []);

  const doPopulateApiKeys = async () => {
    const { data } = await getApiKeys();
    console.log({ apiKeyData: data });
    setApiKeys(data);
  };

  const doPostToken = async body => {
    const oneTimeTokenData = await postApiKeys({}, body);
    doPopulateApiKeys();
    return oneTimeTokenData;
  };

  const doDeleteToken = async id => {
    await deleteApiKeys(id);
    doPopulateApiKeys();
  };

  return (
    <LayoutNew bgColor="linear-gradient(180deg, #F3F5F7 0%, #FDFDFD 100%);">
      <Container>
        <Title>API Tokens</Title>
        <Gap />
        <AddApiKeyModal onClickApply={body => doPostToken(body)} />
        <Gap />
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                {COLUMNS?.map(col => (
                  <Th>{col?.displayName}</Th>
                ))}
              </tr>
            </thead>
            <tbody>
              {apiKeys?.map(apiKey => (
                <tr>
                  {COLUMNS?.map(col => (
                    <Td>{apiKey[col?.dbName]}</Td>
                  ))}
                  <Td>
                    <TrashIcon onClick={() => doDeleteToken(apiKey?.id)} />
                  </Td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </Container>
    </LayoutNew>
  );
};

export default ApiTokensPage;
