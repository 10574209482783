import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { PlusIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { useState } from "react";
import styled from "styled-components";
import { FileCopy } from "@material-ui/icons";

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  width: 600px;
  height: 400px;
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

const Description = styled.div`
  line-height: 1.5;
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 20px;
  padding-top: 10px;
  width: 560px;
  background-color: white;
  border-top: 1px solid #d8d8d8;
  display: grid;
  justify-content: end;
`;

const Trigger = styled.div``;

const StyledInput = styled.input`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
`;

const Container = styled.div`
  // padding: 20px;
`;

const Align = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
  border-width: thin;
  border-style: solid;
  border-radius: 4px;
`;

const CodeSample = styled.div`
  padding: 8px;
  white-space: pre-wrap;
  code {
    height: 200px;
    line-height: 1.2;
    font-family: monospace;
  }
`;

const Actions = styled.div`
  padding: 8px;
  gap: 10px;
  margin-left: auto;
`;

const StyledFileCopy = styled(FileCopy)`
  color: #497ff3;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const INITIAL_TOKEN_BODY = {
  name: "",
  expiredIn: "3 Months",
  scopeIds: [],
  key: null,
};

const AddApiKeyModal = ({ onClickApply = async tokenBody => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tokenBody, setTokenBody] = useState(INITIAL_TOKEN_BODY);

  const onClickApplyBtn = async () => {
    const oneTimeTokenData = await onClickApply(tokenBody);
    // setIsOpen(false);
    // setTokenBody(INITIAL_TOKEN_BODY);
    setTokenBody({
      ...tokenBody,
      key: oneTimeTokenData.data.key,
    });
    console.log({ tokenBody, oneTimeTokenData });
  };

  // disply the one-time token
  // <p>{tokenBody.key}</p>;
  const OneTimeTokenDisplay = (
    <Container>
      <Description>
        Make sure to copy your personal access token now as you will not be able
        to see this again.
      </Description>
      <Gap />
      <Align>
        <CodeSample
          dangerouslySetInnerHTML={{
            __html: `<code>${tokenBody.key}</code>`,
          }}
        />
        <Actions>
          <StyledFileCopy
            style={{
              height: "16px",
              width: "16px",
            }}
            onClick={e => {
              e.stopPropagation();
              navigator.clipboard.writeText(tokenBody.key);
            }}
          />
        </Actions>
      </Align>
    </Container>
  );

  return (
    <>
      <Trigger onClick={() => setIsOpen(true)}>
        <PlusIcon />
      </Trigger>
      <Modal
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
          setTokenBody(INITIAL_TOKEN_BODY);
        }}
      >
        <ModalContent>
          <Title>Create a new token</Title>
          <Gap />
          <StyledInput
            placeholder="Name"
            value={tokenBody.name}
            onChange={e => setTokenBody({ ...tokenBody, name: e.target.value })}
          />
          <Gap />
          {!!tokenBody.key ? OneTimeTokenDisplay : null}
          <BottomContainer>
            {!!tokenBody.key ? (
              <ButtonWord
                isPrimary
                onClick={() => {
                  setIsOpen(false);
                  setTokenBody(INITIAL_TOKEN_BODY);
                }}
              >
                Close
              </ButtonWord>
            ) : (
              <ButtonWord isPrimary onClick={onClickApplyBtn}>
                Create
              </ButtonWord>
            )}
          </BottomContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddApiKeyModal;
