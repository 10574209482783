import styled from "styled-components";
import { useState } from "react";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import NavHeader from "components/NavHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import DirectoryItemsList from "components/DirectoryItemsList";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import useSearchParamsState from "hooks/useSearchParamsState";
import InputWithBottomSuggestions from "components/InputWithBottomSuggestions";
import { getUserProfileByUserName } from "api/services/projectService";
import {
  decodeStoredJwt,
  getLoggedInUserName,
  isInvestorUser,
} from "api/services/authenticationService";
import {
  AnimatedLogo,
  BoltzbitLogo,
  BoltzhubLogoInner,
  FilesIcon,
  LogoInner,
  PaperClipIcon,
  PlusIcon,
  SendIcon,
} from "components/ui/Icons";
import TooltipNew from "components/ui/TooltipNew";
import DirectoryItemsListPipelines from "components/DirectoryItemsListPipelines";
import ButtonIcon, { ButtonIconTransparent } from "components/ui/ButtonIcon";
import TooltipClick from "components/ui/TooltipClick";
import SourceFilesList from "components/SourceFilesList";
import { parseJson, sleep } from "utils/common";
import SourceFilesListHorizontal from "components/SourceFilesListHorizontal";
import InputWithBottomSuggestionsAndSources from "components/InputWithBottomSuggestionsAndSources";
import { isNil, transform } from "lodash";
import Typewriter from "typewriter-effect/dist/core";
import LayoutNew from "components/LayoutNew";
import { postWordDoc } from "api/services/wordDocsService";
import { format } from "date-fns";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { ArrowUpIcon, VerticalLinesIcon } from "components/IconsNew";
import EmptyChatContent from "components/EmptyChatContent";
import { GreyText } from "pages/chat";
import useLoginFromCode from "hooks/useLoginFromCode";
import EditConfigModal from "components/EditConfigModal";
import {
  deleteFlowConfig,
  getFlowConfigs,
  postFlowConfig,
} from "api/backend/fileSystemEndpoints";
import { GLOBAL_CONFIG, useFlowConfig } from "pages/apps-new/config";

const StyledCenteredWithTopNavLayout = styled(LayoutNew)`
  /* padding: 0;
  display: grid;
  background-color: #f7f9fd;
  height: 100vh; */
`;

const CenteredContent = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto auto 1fr auto;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 100%;
  padding-top: 0px;

  transition: opacity 0.2s;

  height: calc(100vh - 40px);

  @media (max-width: 1000px) {
    padding: 10px;
    height: calc(100vh - 100px);
  }
`;

const StyledBottomSendButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 10px;
  background-color: #497ff3;
  border: none;
  fill: #848484;
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;

  :hover {
    fill: #3e3e3e;
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
`;

const Title = styled.div`
  color: #000000;
  padding-top: 0px;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;

  background: linear-gradient(88.57deg, ${COLOR1} 0%, ${COLOR2} 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const SubTitle = styled.div`
  justify-content: center;
  text-align: center;

  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #6c7275;
`;

const StyledRecoInput = styled(InputWithBottomSuggestionsAndSources)`
  width: 800px;
`;

const FixedContainer = styled.div`
  position: fixed;
  bottom: 60px;
  right: 60px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

// const InputContainer = styled.div`
//   position: relative;
//   /* width: 700px; */
//   transition: opacity 1s;
// `;

const MS_PER_LETTER = 40;
const MS_PAUSE = 3000;
const MS_DELETE = 4;

const START_TIME = 1000;

const INPUT_PLACEHOLDER = "Ask about the file...";

// const SendButton = styled.button`
//   width: 32px;
//   height: 32px;
//   border-radius: 12px;
//   background-color: #497ff3;
//   border: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   :hover {
//     background-color: #3f6cc7;
//   }
// `;

const HomePageNew = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState("");
  const [sources, setSources] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(true);
  const [isCreatingDoc, setIsCreatingDoc] = useState(false);
  const [userSources, setUserSources] = useState([]);
  const [shouldSearchInternet, setShouldSearchInternet] = useState(true);
  const [shouldUseFiles, setShouldUseFiles] = useState(true);
  const [shouldThink, setShouldThink] = useState(false);
  const [config, flowConfigs] = useFlowConfig();

  useEffect(() => {
    if (isInvestorUser()) {
      navigate("/compare-models");
    }
  }, []);

  useEffect(() => {
    doFetchUserProfile();
  }, []);

  const doFetchUserProfile = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    setUserProfile(data);
    setIsLoadingUserProfile(false);
  };

  const openSidebarType = parseJson(searchParams.get("openSidebarType"));

  let greetingText = "Hello there";
  if (userProfile?.name) {
    greetingText = `Hello, ${userProfile?.name}`;
  }

  const effectiveUserInput = shouldThink
    ? `${searchValue} Think deeply.`
    : searchValue;
  const wordDocPayload = {
    directoryPath: "/working-files",
    fileName: `Chat on ${format(new Date(), "dd MMM HH:mm")}`,
    isChat: true,
    content: {
      sources,
      blocks: [
        {
          text: effectiveUserInput,
          isQuery: true,
          userSources,
          styles: [
            {
              isSelection: true,
              start: effectiveUserInput?.length,
              end: effectiveUserInput?.length,
            },
          ],
        },
      ],
    },
  };

  const doSendQuery = async () => {
    setIsCreatingDoc(true);
    let query = searchValue;
    if (shouldThink) {
      query = `${searchValue} Think deeply.`;
    }
    searchParams.set("initialQuery", query);

    // non logged in
    const tokenObj = decodeStoredJwt();
    const isTrialUser = tokenObj?.authorities?.includes("ROLE_TRIAL_USER");
    if (isTrialUser) {
      navigate(
        `/chat?query=${query}&shouldSearchInternet=${shouldSearchInternet}`
      );
      return;
    }

    const { data } = await postWordDoc({}, wordDocPayload);
    setIsCreatingDoc(false);
    navigate(
      `/chat-docs/${data?.id}?shouldSearchInternet=${shouldSearchInternet}`
    );
  };

  const onKeyDown = async e => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      doSendQuery();
    }
  };

  const onPressSend = () => {
    doSendQuery();
  };

  const onClickSaveConfigStr = async newStr => {
    const existingConfig = flowConfigs.find(
      config => config?.userId === getLoggedInUserName()
    );
    await deleteFlowConfig(existingConfig?.id);
    await postFlowConfig({}, { userId: getLoggedInUserName(), config: newStr });
    window.location.reload();
  };

  const isEditingConfig = searchParams?.get("edit") === "true";

  return (
    <StyledCenteredWithTopNavLayout
      isTopBarHidden
      isHeaderEnabled={false}
      logoSrc={userProfile?.organisation}
    >
      <CenteredContent
        style={{
          opacity: isLoadingUserProfile ? 0 : 1,
        }}
      >
        <div />
        <Title style={{ alignSelf: "end", paddingBottom: 20, marginTop: -40 }}>
          {greetingText}
        </Title>
        <InputContainer>
          <StyledBottomSendButton
            style={{
              position: "absolute",
              right: 16,
              top: 10,
              zIndex: 2,
            }}
            onClick={onPressSend}
          >
            <ArrowUpIcon height="14px" />
          </StyledBottomSendButton>

          <StyledRecoInput
            id="input"
            placeholder={"Ask your custom LLM..."}
            value={searchValue}
            sources={sources}
            setSources={newSources => {
              setUserSources(newSources);
              setSources(newSources);
            }}
            onChange={e => setSearchValue(e.target.value)}
            onClickSuggestion={text => setSearchValue(text)}
            onKeyDown={onKeyDown}
            userSources={userSources}
            setUserSources={setUserSources}
            shouldSearchInternet={shouldSearchInternet}
            setShouldSearchInternet={setShouldSearchInternet}
            shouldUseFiles={shouldUseFiles}
            setShouldUseFiles={setShouldUseFiles}
            canUseDb={false}
            shouldThink={shouldThink}
            setShouldThink={setShouldThink}
            suggestionsPosition="bottom"
          />
        </InputContainer>
        <div />
        <GreyText style={{ paddingBottom: 10 }}>
          The model can make mistakes. Check important info.&nbsp;
          <a href="https://boltzbit.com/flow/terms" target="_blank">
            Terms of Services
          </a>
          &nbsp;and&nbsp;
          <a href="https://boltzbit.com/flow/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </GreyText>
      </CenteredContent>

      {isEditingConfig && (
        <FixedContainer>
          <EditConfigModal config={config} onClickSave={onClickSaveConfigStr} />
        </FixedContainer>
      )}
    </StyledCenteredWithTopNavLayout>
  );
};

export default HomePageNew;
