import { OPENAPI_CONFIG } from "api/openapi";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const QUERY_STR = '?sidebar=%7B"type"%3A"api"%7D';

export const PROFILE_URL = `/profile-new${QUERY_STR}`;
export const PLAN_URL = `/profile/plan${QUERY_STR}`;

export const ENDPOINTS = Object.keys(OPENAPI_CONFIG.paths)?.map((path) => {
  let title = path;
  const content = OPENAPI_CONFIG?.paths?.[path];

  const methods = Object.keys(content)
    ?.filter((methodName) => methodName !== "description")
    .map((methodName) => {
      return {
        method: methodName,
        ...content[methodName],
      };
    });
  if (content?.description) {
    title = content?.description;
  }

  const parsed = {
    title,
    url: `/endpoint-doc${QUERY_STR}&path=${path}`,
    path,
    methods,
  };
  return parsed;
});

export const SIDEBAR_ITEMS = [
  // {
  //   title: "Tokens",
  //   url: `/api-tokens${QUERY_STR}`,
  // },
  ...ENDPOINTS,
];

const SideBar = styled.div`
  padding-top: 16px;
`;

export const SideBarItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: #e8ecefbf;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;

  ${(props) =>
    props.isSelected &&
    `
    background: linear-gradient(270deg, #323337 50%, rgba(52, 95, 127, 0.5) 100%);
    color: white;
    box-shadow: 0px 4px 8px 0px #0000001A;
    box-shadow: 0px 1px 0px 0px #FFFFFF0D inset;
  `}

  padding: 12px 20px;
  padding-right: 143px;
  :hover {
    color: white;
  }
`;

const HorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #494949;
  margin: 0px 0;
`;

const Title = styled.div`
  color: grey;
  font-weight: 500;
  padding: 18px 20px;
`;

const ApiSidebar = () => {
  const [searchParams] = useSearchParams();

  return (
    <SideBar>
      <SideBarItem
        to={`/api-tokens${QUERY_STR}`}
        isSelected={window?.location?.pathname === "/api-tokens"}
      >
        Tokens
      </SideBarItem>
      <HorizontalLine />
      <Title>Endpoints</Title>
      {SIDEBAR_ITEMS.map((item, index) => {
        const isSelected = searchParams?.get("path") === item?.path;

        return (
          <SideBarItem to={item.url} isSelected={isSelected} key={index}>
            {item.title}
          </SideBarItem>
        );
      })}
    </SideBar>
  );
};

export default ApiSidebar;
